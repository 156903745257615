import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class EntryGuest extends Component {
  constructor(props) {
    super(props);
  }
  state = { email:'', password:'' }
  Catch = (event) => {
    if (event.target.id == 'login-email') { this.setState({ ...this.state, email:event.target.value }) }
    if (event.target.id == 'login-password') { this.setState({ ...this.state, password:event.target.value }) }
  }
  Login = () => {

    try {
      let uri = (window.location.hostname == 'cms.blob.eu.org') ? '/api/login' : 'http://100.112.10.59:8001/api/login';
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.state.email, password: this.state.password })
      }

      fetch(uri, options)
      .then(res => {
        if (!res.ok) {
          alert('Network error')
        } else {
          return res.json();
        }
      })
      .then(r => {
        if (r.status == 'OK' && r.data.status == 'OK') {
          sessionStorage.setItem('token', r.data.token)
          sessionStorage.setItem('language', r.data.language)
          sessionStorage.setItem('email', r.data.email)
          sessionStorage.setItem('name', r.data.name)
          setTimeout(()=>{
            window.location.reload(true)
          },2000)
        } else {
          alert(r.data.reason)
        }
      })
      .catch(error => {
        alert(error)
      })
  
    } catch(err) {
      console.log(err)
    } finally {
      this.setState({ ...this.state, email:'', password:'' });
      document.getElementById('login-email').value = '';
      document.getElementById('login-password').value = '';
    }

  }
  render() {
    return (

        <div style={{ paddingTop:'30px', margin:'auto', width:'500px' }}>
          <div style={{marginTop:'50px', padding:'15px', borderRadius:'12px', border:'1px solid #ddd'}}>
            <div style={{fontWeight:'bold', fontSize:'26px', marginBottom:'10px'}}>Login is required:</div>
            <Form>
              <Form.Group className="mb-3" controlId="login-email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" onChange={ this.Catch } />
              </Form.Group>

              <Form.Group className="mb-3" controlId="login-password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" onChange={ this.Catch } />
              </Form.Group>
            </Form>
          </div>
          <div style={{textAlign:'right',marginTop:'10px'}}>
            <Button variant="primary" onClick={this.Login}>Login</Button>
            <Button variant="secondary" style={{ margin:'0 0 0 20px' }}>Reset</Button>
          </div>
        </div>
      
    );
  }
}
 
export default EntryGuest;