import React, { Component } from 'react';

import EntryGuest from './guest';
import EntryMember from './member';

class EntryPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {  }

  componentDidMount = () => {
    if (!this.mounted) {
      const loginkey = sessionStorage.getItem('token') || ''
      if (loginkey.length > 0) {
        this.setState({ ... this.state, login:1 })
      } else {
        this.setState({ ... this.state, login:0 })
      }
      this.mounted = true
    }
  }

  render() { 
    return (
      <>
        {(this.state.login === 1) ? ( <EntryMember /> ) : ( <EntryGuest /> )}
      </>
    );
  }
}
 
export default EntryPage;