import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import NotFound from "../NotFound";
import LogOut from './LogOut';

import PageIndex from './PageIndex';

class EntryMember extends Component {
  constructor(props) {
    super(props);
  }
  state = {  }
  render() { 
    return (
      <Routes>
        <Route path="/" element={ <PageIndex /> } />
        <Route path="/2" element={<span>2</span>} />
        <Route path="/3" element={<span>3</span>} />
        <Route path="/logout" element={ <LogOut /> } />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}
 
export default EntryMember;