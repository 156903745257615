import React, { Component } from 'react';

class PageIndex extends Component {
  constructor(props) {
    super(props);
  }
  state = {  }
  render() { 
    return (
      <>member Index Page</>
    );
  }
}
 
export default PageIndex;