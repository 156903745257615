import React, { Component } from 'react';

class LogOut extends Component {
  constructor(props) {
    super(props);
  }
  state = {  }
  mounted = false
  componentDidMount = () => {
    if (!this.mounted) {
      const items = ['token','language','email','name'];
      for(let i=0;i<items.length;i++) {
        sessionStorage.removeItem(items[i]);
      }
      setTimeout(()=>{
        window.location.href = '/';
      },3000);
      this.mounted = true
    }
  }
  render() { 
    return (
      <>
      <div>Please wait...</div>
      </>
    );
  }
}
 
export default LogOut;