import React, { Component } from 'react';

class NotFound extends Component {
  constructor(props) {
    super(props);
  }
  state = {  }
  render() { 
    return (
      <div style={{padding:'20px',textAlign:'center'}}>
        <span>404</span>
        &nbsp;&nbsp;
        <span>page not found</span>
      </div>
    );
  }
}
 
export default NotFound;